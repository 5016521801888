import React from "react";
import "./FotterAndSignNews.css";
import { strings } from "../translations/strings";

const FotterAndSignNews = () => {
  return (
    <div className="fotterAndSignNews">
      <div className="line-2-main-content container-sm text-center">
        <div className="main-col-1 main-tab al-center ">
          <img
            src={require("../intro/assets/maliProgramerLogo.png")}
            alt=""
          ></img>
          <p>{strings.footer_description}</p>
        </div>
        <div className="main-col-2 main-tab">
          <div className="main-col-2-inner">
            <h4>{strings.opening_time}</h4>
            <p>{strings.opening_time_description}</p>
            <p>{strings.opening_time_from}</p>
          </div>
        </div>
        <div className="main-col-3 main-tab text-center al-center">
          <h4>{strings.our_programs_label}</h4>
          <ul className="list-link">
            <li>
              <p className="mg1">{strings.program_card1_title}</p>
            </li>
            <li>
              <p className="mg1">{strings.program_card2_title}</p>
            </li>
            <li>
              <p className="mg1">{strings.program_card3_title}</p>
            </li>
          </ul>
          <div className="links al-center">
            <h4>Contact Us</h4>
            <span>
              <i className="fa fa-map-marker" aria-hidden="true"></i>  {strings.footer_address}
            </span>
            <a href="mailto:ado.fajkic@gmail.com">
              <i className="far fa-envelope" aria-hidden="true"></i>  {strings.footer_mail}
            </a>
            <a href="tel:+38761457790">
              <i className="fa fa-phone" aria-hidden="true"></i>  {strings.footer_phone}
            </a>
            <a
              href="https://www.facebook.com/maliprogramertravnik/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-f"></i>  {strings.footer_face_label}
            </a>
            <a
              href="https://www.instagram.com/mali.programer/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>  {strings.footer_insta_label}
            </a>
          </div>
        </div>
      </div>
      <div className="line-3-copyright container-sm">
        <p>{strings.footer_copyright}</p>
      </div>
    </div>
  );
};

export default FotterAndSignNews;
