import "./wave.css";
import { strings } from "../translations/strings";

const WaveCardSection = ({ motion }) => {
  return (
    <>
      <section className="wave padding-view">
        <div className="container">
          <div className="row justify-content-center">
            <div className="text-light pt-5 my-5 text-center col-12">
              <h1 className="fw-bold">{strings.why_choose_us_subtitle}</h1>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 text-center my-5">
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
              >
                <div className="papers py-4 px-3">
                  <div className="mb-3">
                    <img
                      src={require(`./assets/kucice.png`)}
                      alt=""
                      className="image-flow"
                    />
                  </div>
                  <h4 className="text-primary title fw-bold">
                    {strings.why_choose_us_card1_title}
                  </h4>
                  <p className="text-secondary p-2 word-space">
                    {strings.why_choose_us_card1_text}
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center my-5">
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <div className="papers py-4 px-3">
                  <div className="mb-3">
                    <img
                      src={require(`./assets/moon.png`)}
                      alt=""
                      className="image-flow"
                    />
                  </div>
                  <h4 className="text-primary title fw-bold">
                    {strings.why_choose_us_card2_title}
                  </h4>
                  <p className="text-secondary p-2 word-space">
                    {strings.why_choose_us_card2_text}
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center my-5">
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                viewport={{ once: true }}
              >
                <div className="papers py-4 px-3">
                  <div className="mb-3">
                    <img
                      src={require(`./assets/lion.png`)}
                      alt=""
                      className="image-flow"
                    />
                  </div>
                  <h4 className="text-primary title fw-bold">
                    {strings.why_choose_us_card3_title}
                  </h4>
                  <p className="text-secondary p-2 word-space">
                    {strings.why_choose_us_card3_text}
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center my-5">
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                viewport={{ once: true }}
              >
                <div className="papers py-4 px-3 wave-card4">
                  <div className="mb-3">
                    <img
                      src={require(`./assets/horse.png`)}
                      alt=""
                      className="image-flow"
                    />
                  </div>
                  <h4 className="text-primary title fw-bold">
                    {strings.why_choose_us_card4_title}
                  </h4>
                  <p className="text-secondary p-2 word-space">
                    {strings.why_choose_us_card4_text}
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WaveCardSection;
