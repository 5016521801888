import "./App.css";
import Intro from "./intro/Intro";
import FotterAndSignNews from "./fotterAndSignNews/FotterAndSignNews";
import WaveCardSection from "./wave-cards";
import ParentsFeedback from "./parents-feedback";
import AboutUs from "./components/about-us";
import OurPrograms from "./components/our-programs";
import OnlineRegistration from "./components/online-registrations";
import TeacherGallery from "./teacher-gallery/Teacher-Gallery.js";
import PartnerSlider from "./partner-slider";
import { motion } from "framer-motion";
import ScrollButton from "./toTopButton/toTop";

function App() {
  return (
    <div className="App">
      <ScrollButton />
      <Intro motion={motion} />
      <WaveCardSection motion={motion} />
      <AboutUs motion={motion} />
      <OurPrograms motion={motion} />
      <OnlineRegistration motion={motion} />
      <TeacherGallery motion={motion} />
      <ParentsFeedback motion={motion} />
      <PartnerSlider motion={motion} />
      <FotterAndSignNews motion={motion} />
    </div>
  );
}

export default App;
