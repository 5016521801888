import React from "react";
import Slider from "react-slick";
import "./partner-slider.css";
import { strings } from "../translations/strings";

const PartnerSlider = ({ motion }) => {
  return (
    <section className="my-5 partner-slider-background">
      <div className="container">
        <div className="text-center py-5">
          <div className="d-flex justify-content-center mb-3">
            <h4 className="text-danger">{strings.parnter_slider_title}</h4>
          </div>
          <h3 className="second-text">{strings.partner_slider_subtitle}</h3>
          <div className="pt-lg-3">
            <Slider
              dots={false}
              infinite={true}
              speed={2000}
              slidesToShow={3}
              slidesToScroll={3}
              arrows={false}
              initialSlide={0}
              autoplay={true}
              autoplaySpeed={10000}
              cssEase="linear"
            >
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://ksc-travnik.net"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/ksc.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://www.ministryofprogramming.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/mop.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://opcinatravnik.com.ba/ba/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/travnik.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://sbk-ksb.gov.ba/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/kanton.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://www.bhtelecom.ba/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/bht.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://www.mistral.ba/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/mistral.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://www.agencija.dan.ba/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/dan.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://bit-alliance.ba/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/bit.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
              <div className="p-lg-3 p-1 p-md-2">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <a
                    href="https://unvi.edu.ba/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid slide-image"
                      src={require("./assets/vitez.png")}
                      alt=""
                    />
                  </a>
                </motion.div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerSlider;
