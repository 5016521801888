import React from "react";
import "./Teacher-gallery.css";
import { strings } from "../translations/strings";
import Slider from "react-slick";
import { photoSettings, teachSettings } from "./teacher-gallery-settings";

const TeacherGallery = ({ motion }) => {
  return (
    <div className="teacher-gallery-container mt-5" id="profesori">
      <div className="teacher-gallery">
        <div className="teacher-header">
          <h2>{strings.meet_our_teachers_label}</h2>
        </div>
        <div className="teachers">
          <Slider {...teachSettings}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="teacherMotion"
            >
              <div className="teach teach1">
                <img src={require("./assets/jasmin.jpg")} alt="teacher" />
                <h4>{strings.teach_name_1}</h4>
                <p>{strings.teach_description_1}</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="teacherMotion"
            >
              <div className="teach teach2">
                <img src={require("./assets/ado.jpg")} alt="teacher" />
                <h4>{strings.teach_name_2}</h4>
                <p>{strings.teach_description_2}</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="teacherMotion"
            >
              {" "}
              <div className="teach teach3">
                <img src={require("./assets/adis.jpg")} alt="teacher" />
                <h4>{strings.teach_name_3}</h4>
                <p>{strings.teach_description_3}</p>
              </div>
            </motion.div>
          </Slider>
        </div>
        <div className="gallery-bottom">
          <Slider {...photoSettings}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-1.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-2.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-3.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-4.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-1.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-2.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-3.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-4.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-1.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-2.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-3.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={require("./assets/img-4.jpg")}
                alt=""
                className="gallery-img"
              ></img>
            </motion.div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TeacherGallery;
