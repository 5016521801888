import React from "react";
import { strings } from "../translations/strings";
import "./Intro.css";

const Intro = ({ motion }) => {
  return (
    <div className="container-sm">
      <div className="header">
        <div className="head">
          <img
            id="logo"
            src={require("./assets/maliProgramerLogo.png")}
            alt="Mali Programer"
          ></img>
        </div>
        <nav className="navbar navbar-expand-sm navbar-light nav-orange">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav justify-content-evenly align-items-center w-100">
                <li className="nav-item">
                  <a className="nav-link  text-white" href="#onama">
                    {strings.featurs_label}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link  text-white" href="#programi">
                    {strings.news_label}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link  text-white" href="#registracija">
                    {strings.pricing_label}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link  text-white" href="#profesori">
                    {strings.profesori_label}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="welcome-page">
        <div className="container justify-content-between conti">
          <div className="text col-lg-6">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <p className="fs-1 fw-semibold text-primary">
                {strings.we_care_child_study_label}
              </p>
            </motion.div>
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <p className="big-font fw-semibold text-primary">
                {strings.start_learning_with_label}
              </p>
            </motion.div>

            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <div className="img-bg">
                <p className="txt fw-semibold text-white">Mali Programer</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
            >
              <div className="container justify-content-start cont-check">
                <p>
                  <i className="fas fa-check ch ch-pink"></i>
                  <span className="fs-5 text-primary">
                    {strings.outdoor_activities_label}
                  </span>
                </p>
                <p>
                  <i className="fas fa-check ch ch-purple"></i>
                  <span className="fs-5 text-primary">
                    {strings.sport_activities_label}
                  </span>
                </p>
                <p>
                  <i className="fas fa-check ch ch-blue"></i>
                  <span className="fs-5 text-primary">
                    {strings.nutritios_food_label}
                  </span>
                </p>
              </div>
            </motion.div>
          </div>
          <motion.div
            initial={{ x: 1000 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="col-lg-6 img-intro-div">
              <img
                className="img-ppl"
                src={require("./assets/slider-1.png")}
                style={{ maxWidth: "190%" }}
                alt=""
              ></img>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
