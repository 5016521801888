export const strings = {
  profesori_label: "Instruktori",
  pricing_label: "Registracija",
  featurs_label: "O nama",
  news_label: "Kursevi",
  we_care_child_study_label: "Brinemo o edukaciji mladih",
  start_learning_with_label: "Zakoračite u svijet programiranja i robotike",
  outdoor_activities_label: "Lego Boost",
  sport_activities_label: "Scratch",
  nutritios_food_label: "JavaScript",
  meet_our_teachers_label: "Upoznajte naš stručni tim!",
  teach_name_1: "Jasmin Fajkić",
  teach_description_1: "JavaScript instruktor",
  teach_name_2: "Ado Fajkić",
  teach_description_2: "Lego Boost, Scratch instruktor",
  teach_name_3: "Adis Fodolović",
  teach_description_3: "Scratch instruktor",
  footer_description:
    "Škola programiranja i robotike Mali programer nastala je kao potreba prepoznata od strane osnivača za ovim vidom neformalnog obrazovanja i usmjeravanja djece.",
  footer_address: "Mali trg, Donja čaršija, Travnik",
  footer_mail: "info@maliprogramer.ba",
  footer_phone: "+387 61 475 790",
  footer_face_label: "Facebook",
  footer_insta_label: "Instagram",
  opening_time: "Radno vrijeme",
  opening_time_description: "Ponedeljak - Petak",
  opening_time_from: "17:00 - 22:00",
  closed_time: "Praznicima smo",
  closed_time_closed: "Zatvoreni",
  footer_copyright: "©2022 Mali Programer. All Rights Reserved.",
  why_choose_us_subtitle: "Najbolji izbor za vaše dijete",
  why_choose_us_card1_title: "Učenje kroz zabavu",
  why_choose_us_card1_text:
    "Škola Mali programer kroz inovativne edukativne metode objedinjuje usvajanje znanja, ali bez restriktivnih metoda. Tek kada je uključena zabava, dječija mašta radi.",
  why_choose_us_card2_title: "Dokazani rezultati",
  why_choose_us_card2_text:
    "Iako relativno mlada, naša škola bila je domaćin određenog broja evenata, a nekoliko generacija djece uspješno je savladalo prepreke, te nastavilo učenje na naprednijim nivoima.",
  why_choose_us_card3_title: "Instrumenti za rad",
  why_choose_us_card3_text:
    "Mali programer svim svojim polaznicima nudi apsolutno svu potrebnu tehniku za praktičan rad. Upravo kroz praksu razvijamo analitičke i logičke vještine mališana.",
  why_choose_us_card4_title: "Certificirani predavači ",
  why_choose_us_card4_text:
    "Jedan od temelja uspješne škole su i kvalitetni predavači. S ponosom možemo istaknuti da u našoj školi rade predavači dokazani u branši, a koji imaju i validne certifikate kojima se potvrđuje znanje.",
  parents_feedback_subtitle1: "Šta roditelji kažu o našoj školi",
  parents_feedback_card1_name: "Aida Jugo Kišija",
  parents_feedback_card1_profession: "Roditelj polaznika",
  parents_feedback_card1_quote:
    "“ Mali programer je mnogo više od samog programiranja i robotike. To je mjesto gdje je ljubav premo LEGO kockicama pretvorena istovremeno i u čas učenja  i druženja, i motivacije  i opuštanja. Mjesto gdje dijete rado odlazi i sa kojeg se vraća sretno i zadovoljno. Ukoliko za učitelja dobijete Adu  onda ste pravi sretnik. U Adi je naše dijete pronašlo prijatelja, oslonac i podršku, a mi kao roditelji nekoga ko velikodušno želi prenijeti svoje znanje na mlađe generacije. ”",
  parents_feedback_card2_name: "Lejla Tabak",
  parents_feedback_card2_profession: "Roditelj polaznika",
  parents_feedback_card2_quote:
    "“ Moje dijete je uz školu Malog programera kroz scratch razvilo sposobnosti logičkog razmišljanja i proširilo svoje vidike u polju informatike, i svima bih toplo preporučila školu Malog programera. ”",
  parnter_slider_title: "Naši prijatelji",
  partner_slider_subtitle:
    "Upoznajte naše prijatelje koji su omogućili da ostvarimo ovaj san",
  about_us_title: "O nama",
  about_us_desc1: "Škola programiranja i robotike Mali programer nastala je kao potreba prepoznata od strane osnivača za ovim vidom neformalnog obrazovanja i usmjeravanja djece.",
  about_us_desc3: "Pored svakodnevnih časova u prostorijama naše Škole, nerijetko izlazimo u susret formalnim obrazovnim ustanovama, gdje vršimo edukacije, a također smo u naš grad donijeli i globalni event - Kids Hack Day.",
  about_us_desc2: "Činjenica je da je IT branša trenutno najatraktivnije globalno zanimanje, a ukoliko se to usmjeravanje kroz igru praktikuje od mladosti, rezultati su efektivniji.",
  about_us_desc4: " Vizija Malog programera je kontinuiran rad sa mladima, što će u perspektivi i njima i njihovoj porodici itekako biti korisno.", 
  register_part1: "Želite upisati svoje dijete u našu školu? ",
  register_part2: "Javite nam se kroz formu ispod i očekujte naš poziv!",
  our_programs_label: "Naši programi",
  our_programs_desc1: "Pružamo edukaciju mladima",
  our_programs_desc2: "Da bi naša budućnost bila bolja",
  program_card1_title: "JavaScript",
  program_card1_desc: "Uđite u svijet programiranja sa jednim od najpouplarnijh programskih jezika - Javascript",
  program_card1_age: "13-18 godina",
  program_card1_duration_month: "8 časova/mj",
  program_card1_price: "60 KM/mj",
  program_card1_duration: "3 Mjeseca",
  program_card2_title: "Scratch",
  program_card2_desc: "Scratch je dinamični vizualni programski jezik namijenjen djeci za pravljenje igara i animacija.",
  program_card2_age: "8-12 godina",
  program_card2_duration_month: "8 časova/mj",
  program_card2_price: "60 KM/mj",
  program_card2_duration: "3 Mjeseca",
  program_card3_title: "Lego Boost",
  program_card3_desc: "Lego boost uvodi djecu u svijet lego robota na interesantan , interaktivan i zabavan način.",
  program_card3_age: "4-7 godina",
  program_card3_duration_month: "8 časova/mj",
  program_card3_price: "60 KM/mj",
  program_card3_duration: "3 Mjeseca",
};
