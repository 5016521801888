import React from "react";
import "../App.css";
import About from "../assets/abaut_as.png";
import { strings } from "../translations/strings";

const AboutUs = ({ motion }) => {
  return (
    <div className="">
      <section id="onama" className="aboutus pb-md-5 mb-md-5">
        <div className="container mt-5 d-lg-flex pos-rel">
          <div className="w-special-50">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img className="width-image" src={About} alt="" />
            </motion.div>
          </div>
          <div className="w-special-50 about-main-content">
            <p className="arrow">{strings.about_us_title}</p>
            <div className="width-special">
              <p className="lorems lead text-secondary">
                {strings.about_us_desc1}
              </p>
              <p className="lorems lead text-secondary">
                {strings.about_us_desc2}
              </p>
              <p className="lorems lead text-secondary">
                {strings.about_us_desc3}
              </p>
              <p className="lorems lead text-secondary">
                {strings.about_us_desc4}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
