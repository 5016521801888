export const modalData = [
    {
        header: "JavaScript",
        detail:
          "Programski jezici su kao i jezici kojima ljudi pričaju. Da bismo mogli pričati, najprije moramo naučiti riječi i gramatiku. Isto je i sa programskim jezicima – da bismo mogli pisati kod moramo naučiti logiku kodiranja i sintaksu programskog jezika. Sa logikom kodiranja smo započeli na kursu Mali programeri - velika djela: Scratch, a sa njom ćemo nastaviti i na kursu Mali programeri - velika djela: JavaScript. Na ovom kursu ćemo započeti učenje sintakse programskog jezika JavaScript. Djeca će kroz zanimljive primjere i igrice koje će iskodirati u toku kursa razumjeti programiranje na mnogo dubljem nivou.",
        links: [
          "https://vampire-movies-rados.netlify.app/",
          "https://millionaire-rados.netlify.app/",
          "https://weather-app-emel.netlify.app/",
          "https://themovies-emel.netlify.app/",
          "https://todolist-emel.netlify.app/",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        names: [
          "Vampire Movies",
          "Millionaire",
          "Weather App",
          "The Movies",
          "Todo List",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
          
      },
      {
        header: "Scratch",
        detail:
          "Nakon LEGO Boosta, naša preporuka je da se krene u svijet Scratcha. U ovom kursu pravimo korak naprijed ka programiranju, a naša iskustva govore da se sreća mališana uvećava sa svakom igricom koju kreiraju, a nerijetko su prvi konzumenti video igre njihova porodica.",
        links: [
          "https://scratch.mit.edu/projects/657144091/",
          "https://scratch.mit.edu/projects/646894735/",
          "https://scratch.mit.edu/projects/635758335/",
          "https://scratch.mit.edu/projects/636864297/",

          "https://scratch.mit.edu/projects/651907546/",
          "https://scratch.mit.edu/projects/669847274/",
          "https://scratch.mit.edu/projects/712762149/",
          "https://scratch.mit.edu/projects/612396547/",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        names: [
          "Cooking Time",
          "Speed Cube",
          "John vs zombies",
          "Survive",
          "",
          "Super Baseball",
          "STOP ThiS ON canyon",
          "Egipat Slastičarna",
          "Lanino Nebo",
          "",
          "",
          "",
          "",
          "",
          "",
        ]
      },
      {
        header: "Lego Boost",
        detail:
          "Za najmlađe smo pripremili naš LEGO Boost kurs, koji kroz igru i zabavu uvodi u svijet pragmatičnih IT vještina. Uz kreiranje robota, dinosaursa i drugih oblika, Vaše dijete će zavoljeti svaki čas našeg kursa.",
  
      },
]