import "./parents-feedback.css";
import { strings } from "../translations/strings";

const ParentsFeedback = ({ motion }) => {
  return (
    <section className="parents-feedback-bg parents-feedback-padding py-5 mt-5">
      <div className="container">
        <div className="px-5 d-flex justify-content-center mb-3">
          <div className="col-lg-8">
            <div className="text-center">
              <h2>
                {strings.parents_feedback_subtitle1}{" "}
                <span className="title-orange-color">
                  {strings.parents_feedback_brand}
                </span>{" "}
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-lg-between justify-content-center container mx-0 py-5">
          <div className="col-12 col-md-10 col-lg-5 mt-md-5 paper-bg p-5">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <div className="p-1">
                <div className="d-flex-custom col-12 mt-4">
                  <div className="">
                    <img
                      src={require("./assets/aida.jpg")}
                      className="paper-profile-picture"
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <h4>{strings.parents_feedback_card1_name}</h4>
                    <p className="lead">
                      {strings.parents_feedback_card1_profession}
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="">{strings.parents_feedback_card1_quote}</p>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="col-12 col-md-10 col-lg-5 mt-5 paper-bg p-5">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <div className="p-1">
                <div className="d-flex-custom col-12 mt-4">
                  <div>
                    <img
                      src={require("./assets/lejla.jpg")}
                      className="paper-profile-picture"
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <h4>{strings.parents_feedback_card2_name}</h4>
                    <p className="lead">
                      {strings.parents_feedback_card2_profession}
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <p>{strings.parents_feedback_card2_quote}</p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ParentsFeedback;
