import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "../App.css";
import { strings } from "../translations/strings";
import "react-phone-number-input/style.css";
import Input, { isValidPhoneNumber }  from "react-phone-number-input/input";

const OnlineRegistration = ({ motion }) => {
  const [loader, setLoader] = useState(false);

  const [registerForm, setRegisterForm] = useState({
    name: "",
    course: "",
    phoneNumber: "",
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoader(true);
    emailjs
      .sendForm(
        "service_cnii2m8",
        "template_kn6m6zj",
        form.current,
        "I3NsZm0iPMBD-FfSI"
      )
      .then(
        (result) => {
          setRegisterForm({ name: "", phoneNumber: "", course: "" });
          setLoader(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const isSendDisabled  = () => {
    if(Object.values(registerForm).includes("")) {
      return true
    }
    if(!registerForm?.phoneNumber) {
      return true
    }
    if(!isValidPhoneNumber(registerForm?.phoneNumber)) {
      return true
    }
    return false
  }
  
  return (
    <div className="register text-center pt-5 mt-2" id="registracija">
      <h1 className="text px-3 mb-5 w-80">
        {strings.register_part1}
        <br />
        {strings.register_part2}
      </h1>

      <div className="mt-5 justify-content-center form col-12 p-5 max-registration registr">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="row">
            <form className="row" ref={form} onSubmit={sendEmail}>
              <div className="col-12 max-input col-md-6">
                <input
                  value={registerForm.name}
                  placeholder="Ime I Prezime"
                  className="input1 w-100 my-3 input_form"
                  name="from_name"
                  onChange={(e) =>
                    setRegisterForm({ ...registerForm, name: e.target.value })
                  }
                />
              </div>
              <div className="col-12 max-input col-md-6">
                <select
                  name="course_name"
                  className="input1 w-100 my-3 input_form fnt-size"
                  onChange={(e) =>
                    setRegisterForm({ ...registerForm, course: e.target.value })
                  }
                  value={registerForm.course}
                >
                  <option value="kurs">Izaberite kurs</option>
                  <option value="Scratch">Scratch kurs</option>
                  <option value="Robotika">Kurs robotike</option>
                  <option value="Javascript">Kurs Javascripta</option>
                </select>
              </div>
              <div className="col-12 max-input col-md-6">
                <Input
                  name="phone_number"
                  defaultCountry="BA"
                  withCountryCallingCode={true}
                  country="BA"
                  smartCaret={true}
                  placeholder="Broj Telefona"
                  className="input1 w-100 my-3 input_form"
                  value={registerForm.phoneNumber}
                  onChange={(value) =>
                    setRegisterForm({
                      ...registerForm,
                      phoneNumber: value,
                    })
                  }
                />
              </div>
              <div className="col-12 max-input col-md-6">
                <input
                  type="submit"
                  disabled={isSendDisabled()}
                  id="enroll"
                  className={
                    loader
                      ? `btn btn-primary btn-lg w-100 my-3 loader`
                      : "btn btn-primary btn-lg w-100 my-3"
                  }
                  value={loader ? "" : `Pošalji ➔`}
                />
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default OnlineRegistration;
